import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Layout from '../components/layout';
import { about, content, image } from './planer-och-kontakt.module.scss';

import Zoom from '../components/Zoom';

const PlansAndContactPage = () => {
    return (
        <Layout pageTitle="Planer & Kontakt" largeHero={false}>
            <div className={content}>
                <Zoom wrapperClass={image}>
                    <StaticImage loading="eager" src={'../images/valpar2021.jpg'} alt={"Valpkullen 2021"} aspectRatio={1.2} />
                </Zoom>

                <div className={about}>
                    <h1>Kontakt</h1>
                    <p>Kontakta oss för att få veta mer om framtida planer på mailen <a href="mailto:sagotassenskennel@gmail.com">sagotassenskennel@gmail.com</a>.</p>
                    <br />
                </div>
            </div>
        </Layout>
    )
}

export default PlansAndContactPage